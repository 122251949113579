import React, { useState } from "react";
import Header4 from "./../Common/Header4";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import GoogleMapReact from "google-map-react";
import useSubmitContact from "../../hooks/useSubmitContact";

var bnrimg = require("./../../images/banner/all.jpg");

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function ContactUs() {
  const [inputHandle, submitForm, errorObj, formObj] = useSubmitContact();

  const defaultProps = {
    center: {
      lat: 34.07328,
      lng: -118.25141,
    },
    zoom: 12,
  };

  return (
    <>
      <Header4 />
      <div className="page-content">
        <Banner
          title="Contact Us"
          pagename="Contact us"
          description="Our team of experts is dedicated to providing you with exceptional service and answering any questions you may have. We value your feedback and look forward to hearing from you."
          bgimage={bnrimg}
        />
        {/* SECTION CONTENTG START */}
        <div className="section-full p-tb80 inner-page-padding">
          {/* LOCATION BLOCK*/}
          <div className="container">
            {/* GOOGLE MAP & CONTACT FORM */}
            <div className="section-content">
              {/* CONTACT FORM*/}
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <form
                    className="contact-form cons-contact-form bg-gray p-a30"
                    method="post"
                    action="#"
                  >
                    <div className="contact-one">
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                          <div
                            className="sx-separator bg-white bg-moving bg-repeat-x"
                            style={{
                              backgroundImage:
                                "url(images/background/cross-line2.png)",
                            }}
                          >
                            <h3 className="sep-line-one">Form</h3>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="form-group">
                        <input
                          name="username"
                          type="text"
                          required
                          value={formObj.name}
                          className="form-control"
                          placeholder="Name"
                          onChange={inputHandle("name")}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="email"
                          type="text"
                          className="form-control"
                          required
                          value={formObj.email}
                          placeholder="Email"
                          onChange={inputHandle("email")}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="text"
                          type="text"
                          className="form-control"
                          required
                          value={formObj.phone}
                          placeholder="Phone"
                          onChange={inputHandle("phone")}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows={4}
                          className="form-control "
                          required
                          value={formObj.desc}
                          placeholder="Message"
                          onChange={inputHandle("desc")}
                        />
                      </div>
                      {errorObj.error && (
                        <p className="text-red">{errorObj.message}</p>
                      )}
                      {errorObj.success && (
                        <p className="text-green">{errorObj.message}</p>
                      )}
                      {errorObj.isLoading && (
                        <p className="text-yellow">{errorObj.message}</p>
                      )}
                      <div className="text-right">
                        <button
                          name="submit"
                          type="button"
                          value="Submit"
                          className="site-button btn-half"
                          onClick={submitForm}
                        >
                          <span> submit</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div
                    className="contact-info block-shadow bg-white bg-center p-a40"
                    style={{
                      backgroundImage: "url(images/background/bg-map.png)",
                    }}
                  >
                    <div>
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                          <div
                            className="sx-separator bg-white bg-moving bg-repeat-x"
                            style={{
                              backgroundImage:
                                "url(images/background/cross-line2.png)",
                            }}
                          >
                            <h3 className="sep-line-one">Info</h3>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Phone number</h5>
                          <p>
                            {" "}
                            <a href="tel:i+2348060670074" className="list-dark">
                              +234 (0) 8060670074
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Email address</h5>
                          <p>
                            {" "}
                            <a
                              href="mailto:info@cpnnigeria.com"
                              className="list-dark"
                            >
                              info@cpnnigeria.com
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left">
                        <div className="icon-xs">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Head Office</h5>
                          <p>
                            1b Tafawa Balewa Street, Ilorin GRA, Kwara State
                          </p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left">
                        <div className="icon-xs">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Abuja Office</h5>
                          <p>
                            P.O Box 1132, No 44, Lord Lugard Street, Asokoro,
                            Abuja Garki, Abuja.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="gmap-outline">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={34.07328}
              lng={-118.25141}
              text={<i className="fa fa-map-marker" />}
            />
          </GoogleMapReact>
        </div>
        {/* SECTION CONTENT END */}
      </div>

      <Footer />
    </>
  );
}

export default ContactUs;
